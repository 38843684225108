import React from 'react';
import ReactMarkdown from 'react-markdown';
import { FaUser, FaRobot } from 'react-icons/fa';

function MessageList({ messages, isLoading, onFollowUpClick }) {
  return (
    <div className="messages">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`message ${message.type}`}
        >
          <div className="message-icon">
            {message.type === 'user' ? <FaUser /> : <FaRobot />}
          </div>
          <div className="message-content">
            {message.type === 'user' ? (
              message.text
            ) : (
              <>
                <ReactMarkdown>
                  {typeof message.response === 'object' 
                    ? message.response.response 
                    : message.response}
                </ReactMarkdown>
                {message.response.follow_up_questions && (
                  <div className="follow-up-questions">
                    <h4>Related Questions:</h4>
                    <div className="follow-up-buttons">
                      {message.response.follow_up_questions.map((question, qIndex) => (
                        <button
                          key={qIndex}
                          className="follow-up-button"
                          onClick={() => onFollowUpClick(question)}
                        >
                          {question}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}
      
      {isLoading && (
        <div className="message ai">
          <div className="message-icon">
            <FaRobot />
          </div>
          <div className="thinking-container">
            <div className="thinking-text">Thinking</div>
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MessageList;