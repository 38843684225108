import { db } from '../firebase/config';
import { 
  collection, 
  doc, 
  setDoc, 
  updateDoc, 
  getDocs, 
  query, 
  orderBy,
  getDoc,
  deleteDoc,
  serverTimestamp
} from 'firebase/firestore';

export const saveChat = async (userId, chatId, chatData) => {
  try {
    const chatRef = doc(db, 'users', userId, 'chats', chatId.toString());
    await setDoc(chatRef, {
      ...chatData,
      updatedAt: new Date().toISOString(),
      createdAt: chatData.createdAt || new Date().toISOString()
    });
  } catch (error) {
    console.error('Error saving chat:', error);
    throw error;
  }
};

export const updateChat = async (userId, chatId, chatData) => {
  try {
    const chatRef = doc(db, 'users', userId, 'chats', chatId.toString());
    
    // Check if document exists
    const docSnap = await getDoc(chatRef);
    
    if (docSnap.exists()) {
      // Update existing document
      await updateDoc(chatRef, {
        ...chatData,
        updatedAt: new Date().toISOString()
      });
    } else {
      // Create new document if it doesn't exist
      await setDoc(chatRef, {
        ...chatData,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString()
      });
    }
  } catch (error) {
    console.error('Error updating chat:', error);
    throw error;
  }
};

export const loadUserChats = async (userId) => {
  try {
    const chatsRef = collection(db, 'users', userId, 'chats');
    const q = query(chatsRef, orderBy('updatedAt', 'desc'));
    const querySnapshot = await getDocs(q);
    
    const chats = [];
    querySnapshot.forEach((doc) => {
      chats.push({
        id: doc.id,
        ...doc.data()
      });
    });
    
    return chats;
  } catch (error) {
    console.error('Error loading chats:', error);
    throw error;
  }
};

export const deleteChat = async (userId, chatId) => {
  try {
    const chatRef = doc(db, 'users', userId, 'chats', chatId.toString());
    await deleteDoc(chatRef);
  } catch (error) {
    console.error('Error deleting chat:', error);
    throw error;
  }
};

export const saveUserProfile = async (userId, profileData) => {
  try {
    await setDoc(doc(db, 'users', userId), {
      profile: profileData,
      updatedAt: serverTimestamp()
    }, { merge: true });
  } catch (error) {
    console.error('Error saving user profile:', error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().profile;
    }
    return null;
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw error;
  }
}; 