import React, { useEffect, useRef, useState } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';

const welcomeMessages = [
  "Welcome to RecoverAI-Coach. Ready to dive into your health data? Let's unlock some insights to fuel your progress. What questions can I help you answer?",
  "I'm RecoverAI-Coach, your personal wellness advisor. Curious about your recent sleep patterns or how to improve recovery? Let's explore your data together.",
  "Hi, I'm RecoverAI-Coach. Need some insights on your nutrition or sleep routines? I'm here to help you make the most out of your daily habits.",
  "RecoverAI-Coach here. Let's take a look at your progress. I can help with personalized tips on sleep, diet, and recovery. What's on your mind today?",
  "Welcome to RecoverAI-Coach. Your health data is packed with insights. I'm here to make it clear and practical. Ask me anything about sleep, nutrition, or recovery.",
  "RecoverAI-Coach at your service. Ready to improve your health habits? Your data holds the answers, and I can guide you through it. How can I help today?",
  "I'm RecoverAI-Coach, your wellness optimization assistant. Let's take a closer look at your nutrition, sleep, and overall wellness. What would you like to focus on?",
  "Health and wellness are all about balance. I'm RecoverAI-Coach, and I'm here to guide you through your data. Let's get started with any questions you have.",
  "RecoverAI-Coach here to help you make sense of your health data. From sleep quality to nutrition balance, let's find ways to optimize your journey.",
  "Welcome to RecoverAI-Coach. Let's dive into your latest health stats. I'm here to help make sense of your data and provide insights to improve your sleep, diet, and recovery.",
  "RecoverAI-Coach ready to assist. Your health data shows both progress and opportunity. Let's work together to keep moving toward your goals. What's your focus today?",
  "I'm RecoverAI-Coach, your personal wellness guide. Looking to improve your routines? Your data has valuable insights, and I'm here to help you understand them better.",
  "Hello, RecoverAI-Coach here. I'm ready to help you leverage your data for better health outcomes. Let's start with any questions about sleep, nutrition, or recovery.",
  "RecoverAI-Coach at your service. Let's turn your health data into actionable advice. Whether it's sleep quality, diet, or recovery, I can guide you to better habits.",
  "Welcome to RecoverAI-Coach. Making sense of health data can be tricky, but I'm here to help. Let's dig into your wellness stats together. What questions do you have?",
];

const suggestedPrompts = [
  "How can I improve my recovery score based on the data?",
  "Why is my recovery score fluctuating, and how can I make it more consistent?",
  "What recovery strategies can I use on days when my score is below 33?",
  "How does high day strain impact my recovery, and what can I do to minimize it?",
  "Are there any specific habits I should incorporate to keep my recovery score above 66?",
  "How can I improve my sleep efficiency based on the sleep metrics?",
  "What changes should I make to reduce my sleep disturbances?",
  "How does my sleep debt affect my recovery and strain, and how can I manage it?",
  "What can I do to increase the time spent in REM and deep sleep?",
  "How does the number of sleep cycles relate to my overall sleep quality?",
  "What types of workouts are best for balancing recovery and strain?",
  "Given my workout intensity distribution, should I adjust my workout routine?",
  "How can I optimize my training intensity to avoid burnout?",
  "Are there any specific training techniques I should focus on to improve my average strain score?",
  "Should I be doing more moderate-intensity workouts to balance my high-intensity days?",
  "What foods would help me recover faster after a high-strain workout?",
  "Are there specific nutrients I should focus on to improve my overall recovery score?",
  "How can I fuel my body better strength training workouts?",
  "What should I eat on rest days to support recovery without adding strain?",
  "Are there certain foods that can help improve my sleep quality?",
  "How much water should I be drinking daily to support my recovery and workouts?",
  "Are there specific supplements that could help me improve my recovery score?",
  "Should I adjust my hydration levels based on high or low strain days?",
  "What role does hydration play in sleep quality and efficiency?",
  "Are there supplements that might help me reduce sleep disturbances?",
  "How can I mentally prepare for high-strain days to optimize performance?",
  "What are some stress-management techniques I can use on high-strain days?",
  "Based on my data, what relaxation techniques can I use before bed to improve sleep?",
  "How can I stay motivated on low-recovery days?",
  "Are there any visualization or mental exercises that can help me prepare for wrestling?",
  "How can I improve my VO2 Max based on my workout data?",
  "What is the ideal balance between high-strain and recovery days for peak performance?",
  "How do my workout heart rate zones relate to overall cardiovascular fitness?",
  "What should I focus on in my training to improve my recovery-to-strain ratio?",
  "Based on my current sleep and recovery data, what changes should I make to increase my energy levels?",
  "Why does my recovery score decrease on days with high strain?",
  "What trends should I be most aware of in my recovery, sleep, or strain?",
  "How do my sleep patterns correlate with my strain and recovery scores?",
  "How can I address any negative trends in my sleep or strain data?",
  "Are there any specific improvements I can make based on the correlations found in my data?",
];

function ChatInterface({ messages, onSendMessage, whoopData }) {
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [selectedPrompts] = useState(() => {
    const shuffled = [...suggestedPrompts].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  });
  const [welcomeMessage] = useState(() => {
    return welcomeMessages[Math.floor(Math.random() * welcomeMessages.length)];
  });

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages, isLoading]);

  const handleSendMessage = async (messageData) => {
    setIsLoading(true);
    try {
      await onSendMessage(messageData);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromptClick = (prompt) => {
    handleSendMessage({
      query: prompt,
      whoopData: whoopData
    });
  };

  const handleFollowUpClick = (question) => {
    handleSendMessage({
      query: question,
      whoopData: whoopData
    });
  };

  return (
    <div className="chat-interface">
      <div className="message-list-container">
        {messages.length === 0 && (
          <div className="welcome-container">
            <div className="welcome-message">{welcomeMessage}</div>
            <div className="suggested-prompts">
              {selectedPrompts.map((prompt, index) => (
                <button
                  key={index}
                  className="prompt-button"
                  onClick={() => handlePromptClick(prompt)}
                >
                  {prompt}
                </button>
              ))}
            </div>
          </div>
        )}
        <MessageList 
          messages={messages} 
          isLoading={isLoading} 
          onFollowUpClick={handleFollowUpClick}
        />
        <div ref={messagesEndRef} />
      </div>
      <MessageInput onSendMessage={handleSendMessage} whoopData={whoopData} />
    </div>
  );
}

export default ChatInterface;