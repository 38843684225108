import React, { useState } from 'react';
import '../styles/ProfileForm.css';

const ProfileForm = ({ initialData, onSubmit, isEditing }) => {
  const [formData, setFormData] = useState(initialData || {
    demographics: {
      age: '',
      weight_kg: '',
      height_cm: '',
      gender: '',
      activity_level: '',
      experience_level: ''
    },
    activity_info: {
      primary_activities: [],
      activity_frequency: '',
      weekly_active_hours: '',
      current_phase: '',
      intensity_preference: ''
    },
    goals: {
      primary_goal: '',
      secondary_goals: [],
      specific_targets: []
    },
    health_factors: {
      dietary_restrictions: [],
      medical_conditions: [],
      current_supplements: [],
      sleep_schedule: {
        typical_bedtime: '',
        typical_wake_time: ''
      }
    },
    lifestyle_factors: {
      stress_level: '',
      occupation_type: '',
      daily_active_hours: '',
      nutrition_preparation: '',
      budget_level: '',
      time_constraints: ''
    }
  });

  const activityLevelOptions = ['sedentary', 'light', 'moderate', 'active', 'very_active', 'elite'];
  const experienceLevelOptions = ['beginner', 'intermediate', 'advanced', 'elite'];
  const genderOptions = ['male', 'female', 'other', 'prefer_not_to_say'];
  const phaseOptions = ['bulking', 'cutting', 'maintenance', 'competition_prep', 'recovery'];
  const intensityOptions = ['low', 'moderate', 'high'];
  const stressLevelOptions = ['low', 'moderate', 'high', 'very_high'];
  const occupationOptions = ['sedentary', 'active', 'student', 'mixed'];
  const budgetOptions = ['limited', 'moderate', 'flexible'];
  const preparationOptions = ['minimal', 'moderate', 'extensive'];
  const constraintOptions = ['low', 'moderate', 'high'];

  const handleChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleArrayChange = (section, field, value) => {
    const values = value.split(',').map(v => v.trim());
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: values
      }
    }));
  };

  const handleSleepChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      health_factors: {
        ...prev.health_factors,
        sleep_schedule: {
          ...prev.health_factors.sleep_schedule,
          [field]: value
        }
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form className="profile-form" onSubmit={handleSubmit}>
      <h2>{isEditing ? 'Edit Profile' : 'Complete Your Profile'}</h2>

      <section className="form-section">
        <h3>Demographics</h3>
        <div className="form-grid">
          <div className="form-group">
            <label>Age</label>
            <input
              type="number"
              value={formData.demographics.age}
              onChange={(e) => handleChange('demographics', 'age', parseInt(e.target.value))}
              required
            />
          </div>

          <div className="form-group">
            <label>Weight (kg)</label>
            <input
              type="number"
              step="0.1"
              value={formData.demographics.weight_kg}
              onChange={(e) => handleChange('demographics', 'weight_kg', parseFloat(e.target.value))}
              required
            />
          </div>

          <div className="form-group">
            <label>Height (cm)</label>
            <input
              type="number"
              value={formData.demographics.height_cm}
              onChange={(e) => handleChange('demographics', 'height_cm', parseInt(e.target.value))}
              required
            />
          </div>

          <div className="form-group">
            <label>Gender</label>
            <select
              value={formData.demographics.gender}
              onChange={(e) => handleChange('demographics', 'gender', e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              {genderOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Activity Level</label>
            <select
              value={formData.demographics.activity_level}
              onChange={(e) => handleChange('demographics', 'activity_level', e.target.value)}
              required
            >
              <option value="">Select Activity Level</option>
              {activityLevelOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Experience Level</label>
            <select
              value={formData.demographics.experience_level}
              onChange={(e) => handleChange('demographics', 'experience_level', e.target.value)}
              required
            >
              <option value="">Select Experience Level</option>
              {experienceLevelOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>

      <section className="form-section">
        <h3>Activity Information</h3>
        <div className="form-grid">
          <div className="form-group">
            <label>Primary Activities (comma-separated)</label>
            <input
              type="text"
              value={formData.activity_info.primary_activities.join(', ')}
              onChange={(e) => handleArrayChange('activity_info', 'primary_activities', e.target.value)}
              placeholder="e.g., wrestling, strength_training"
              required
            />
          </div>

          <div className="form-group">
            <label>Activity Frequency (days/week)</label>
            <input
              type="number"
              min="0"
              max="7"
              value={formData.activity_info.activity_frequency}
              onChange={(e) => handleChange('activity_info', 'activity_frequency', parseInt(e.target.value))}
              required
            />
          </div>

          <div className="form-group">
            <label>Weekly Active Hours</label>
            <input
              type="number"
              value={formData.activity_info.weekly_active_hours}
              onChange={(e) => handleChange('activity_info', 'weekly_active_hours', parseInt(e.target.value))}
              required
            />
          </div>

          <div className="form-group">
            <label>Current Phase</label>
            <select
              value={formData.activity_info.current_phase}
              onChange={(e) => handleChange('activity_info', 'current_phase', e.target.value)}
              required
            >
              <option value="">Select Phase</option>
              {phaseOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Intensity Preference</label>
            <select
              value={formData.activity_info.intensity_preference}
              onChange={(e) => handleChange('activity_info', 'intensity_preference', e.target.value)}
              required
            >
              <option value="">Select Intensity</option>
              {intensityOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>

      <section className="form-section">
        <h3>Goals</h3>
        <div className="form-grid">
          <div className="form-group">
            <label>Primary Goal</label>
            <input
              type="text"
              value={formData.goals.primary_goal}
              onChange={(e) => handleChange('goals', 'primary_goal', e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>Secondary Goals (comma-separated)</label>
            <input
              type="text"
              value={formData.goals.secondary_goals.join(', ')}
              onChange={(e) => handleArrayChange('goals', 'secondary_goals', e.target.value)}
              placeholder="e.g., maintain_strength, manage_weight"
              required
            />
          </div>

          <div className="form-group">
            <label>Specific Targets (comma-separated)</label>
            <input
              type="text"
              value={formData.goals.specific_targets.join(', ')}
              onChange={(e) => handleArrayChange('goals', 'specific_targets', e.target.value)}
              placeholder="e.g., maintain_74kg_weight_class"
              required
            />
          </div>
        </div>
      </section>

      <section className="form-section">
        <h3>Health Factors</h3>
        <div className="form-grid">
          <div className="form-group">
            <label>Dietary Restrictions (comma-separated)</label>
            <input
              type="text"
              value={formData.health_factors.dietary_restrictions.join(', ')}
              onChange={(e) => handleArrayChange('health_factors', 'dietary_restrictions', e.target.value)}
              placeholder="e.g., none, gluten-free"
            />
          </div>

          <div className="form-group">
            <label>Medical Conditions (comma-separated)</label>
            <input
              type="text"
              value={formData.health_factors.medical_conditions.join(', ')}
              onChange={(e) => handleArrayChange('health_factors', 'medical_conditions', e.target.value)}
              placeholder="e.g., none, previous_shoulder_strain"
            />
          </div>

          <div className="form-group">
            <label>Current Supplements (comma-separated)</label>
            <input
              type="text"
              value={formData.health_factors.current_supplements.join(', ')}
              onChange={(e) => handleArrayChange('health_factors', 'current_supplements', e.target.value)}
              placeholder="e.g., creatine, whey_protein"
            />
          </div>

          <div className="form-group">
            <label>Typical Bedtime (HH:MM)</label>
            <input
              type="time"
              value={formData.health_factors.sleep_schedule.typical_bedtime}
              onChange={(e) => handleSleepChange('typical_bedtime', e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>Typical Wake Time (HH:MM)</label>
            <input
              type="time"
              value={formData.health_factors.sleep_schedule.typical_wake_time}
              onChange={(e) => handleSleepChange('typical_wake_time', e.target.value)}
              required
            />
          </div>
        </div>
      </section>

      <section className="form-section">
        <h3>Lifestyle Factors</h3>
        <div className="form-grid">
          <div className="form-group">
            <label>Stress Level</label>
            <select
              value={formData.lifestyle_factors.stress_level}
              onChange={(e) => handleChange('lifestyle_factors', 'stress_level', e.target.value)}
              required
            >
              <option value="">Select Stress Level</option>
              {stressLevelOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Occupation Type</label>
            <select
              value={formData.lifestyle_factors.occupation_type}
              onChange={(e) => handleChange('lifestyle_factors', 'occupation_type', e.target.value)}
              required
            >
              <option value="">Select Occupation Type</option>
              {occupationOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Daily Active Hours</label>
            <input
              type="number"
              min="0"
              max="24"
              value={formData.lifestyle_factors.daily_active_hours}
              onChange={(e) => handleChange('lifestyle_factors', 'daily_active_hours', parseInt(e.target.value))}
              required
            />
          </div>

          <div className="form-group">
            <label>Nutrition Preparation</label>
            <select
              value={formData.lifestyle_factors.nutrition_preparation}
              onChange={(e) => handleChange('lifestyle_factors', 'nutrition_preparation', e.target.value)}
              required
            >
              <option value="">Select Preparation Level</option>
              {preparationOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Budget Level</label>
            <select
              value={formData.lifestyle_factors.budget_level}
              onChange={(e) => handleChange('lifestyle_factors', 'budget_level', e.target.value)}
              required
            >
              <option value="">Select Budget Level</option>
              {budgetOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Time Constraints</label>
            <select
              value={formData.lifestyle_factors.time_constraints}
              onChange={(e) => handleChange('lifestyle_factors', 'time_constraints', e.target.value)}
              required
            >
              <option value="">Select Time Constraints</option>
              {constraintOptions.map(option => (
                <option key={option} value={option}>
                  {option.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>

      <button type="submit" className="submit-button">
        {isEditing ? 'Save Changes' : 'Complete Profile'}
      </button>
    </form>
  );
};

export default ProfileForm; 