import { auth } from '../firebase/config';

// Use relative URLs for all API calls
const API_URL = '/api';

export const fetchWhoopData = async (startDate, userId) => {
  try {
    if (!userId) {
      // Return empty array if no user is logged in
      return [];
    }
    
    const response = await fetch(
      `${API_URL}/whoop/summary?start_date=${startDate}&userId=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch Whoop data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching Whoop data:', error);
    throw error;
  }
};

export const sendMessage = async (query, whoopData, conversationHistory, userId) => {
  try {
    // Get the current user's ID token
    const idToken = await auth.currentUser?.getIdToken();
    if (!idToken) {
      throw new Error('You must be logged in to use the chat.');
    }

    const response = await fetch(`${API_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        whoopData,
        conversationHistory,
        userId,
        idToken // Add the ID token to the request
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to send message');
    }

    return await response.json();
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const checkAuthorization = async (userId) => {
  try {
    // Get the current user's ID token
    const idToken = await auth.currentUser?.getIdToken();
    if (!idToken) {
      throw new Error('Not authorized');
    }

    const response = await fetch(`${API_URL}/auth/check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, idToken }),
    });

    if (!response.ok) {
      throw new Error('Not authorized');
    }

    return true;
  } catch (error) {
    console.error('Error checking authorization:', error);
    return false;
  }
};