import React, { useState } from 'react';
import ProfileForm from './ProfileForm';
import '../styles/Profile.css';
import { FaArrowLeft, FaEdit } from 'react-icons/fa';

const Profile = ({ userProfile, onUpdateProfile, onClose }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = async (formData) => {
    await onUpdateProfile(formData);
    setIsEditing(false);
  };

  const formatValue = (value) => {
    if (typeof value === 'string') {
      return value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    }
    return value;
  };

  if (isEditing) {
    return <ProfileForm initialData={userProfile} onSubmit={handleSubmit} isEditing={true} />;
  }

  return (
    <div className="profile-view">
      <div className="profile-header">
        <h2>Your Profile</h2>
        <div className="profile-actions">
          <button className="back-button" onClick={onClose}>
            <FaArrowLeft /> Back to Chat
          </button>
          <button className="edit-button" onClick={() => setIsEditing(true)}>
            <FaEdit /> Edit Profile
          </button>
        </div>
      </div>

      <div className="profile-section">
        <h3>Demographics</h3>
        <div className="profile-grid">
          <div className="profile-item">
            <label>Age</label>
            <span>{userProfile.demographics.age} years</span>
          </div>
          <div className="profile-item">
            <label>Weight</label>
            <span>{userProfile.demographics.weight_kg} kg</span>
          </div>
          <div className="profile-item">
            <label>Height</label>
            <span>{userProfile.demographics.height_cm} cm</span>
          </div>
          <div className="profile-item">
            <label>Gender</label>
            <span>{formatValue(userProfile.demographics.gender)}</span>
          </div>
          <div className="profile-item">
            <label>Activity Level</label>
            <span>{formatValue(userProfile.demographics.activity_level)}</span>
          </div>
          <div className="profile-item">
            <label>Experience Level</label>
            <span>{formatValue(userProfile.demographics.experience_level)}</span>
          </div>
        </div>
      </div>

      <div className="profile-section">
        <h3>Activity Information</h3>
        <div className="profile-grid">
          <div className="profile-item list">
            <label>Primary Activities</label>
            <span>
              {userProfile.activity_info.primary_activities.map(activity => (
                <span key={activity} className="tag">{formatValue(activity)}</span>
              ))}
            </span>
          </div>
          <div className="profile-item">
            <label>Activity Frequency</label>
            <span>{userProfile.activity_info.activity_frequency} days/week</span>
          </div>
          <div className="profile-item">
            <label>Weekly Active Hours</label>
            <span>{userProfile.activity_info.weekly_active_hours} hours</span>
          </div>
          <div className="profile-item">
            <label>Current Phase</label>
            <span>{formatValue(userProfile.activity_info.current_phase)}</span>
          </div>
          <div className="profile-item">
            <label>Intensity Preference</label>
            <span>{formatValue(userProfile.activity_info.intensity_preference)}</span>
          </div>
        </div>
      </div>

      <div className="profile-section">
        <h3>Goals</h3>
        <div className="profile-grid">
          <div className="profile-item">
            <label>Primary Goal</label>
            <span>{formatValue(userProfile.goals.primary_goal)}</span>
          </div>
          <div className="profile-item list">
            <label>Secondary Goals</label>
            <span>
              {userProfile.goals.secondary_goals.map(goal => (
                <span key={goal} className="tag">{formatValue(goal)}</span>
              ))}
            </span>
          </div>
          <div className="profile-item list">
            <label>Specific Targets</label>
            <span>
              {userProfile.goals.specific_targets.map(target => (
                <span key={target} className="tag">{formatValue(target)}</span>
              ))}
            </span>
          </div>
        </div>
      </div>

      <div className="profile-section">
        <h3>Health Factors</h3>
        <div className="profile-grid">
          <div className="profile-item list">
            <label>Dietary Restrictions</label>
            <span>
              {userProfile.health_factors.dietary_restrictions.length > 0 
                ? userProfile.health_factors.dietary_restrictions.map(restriction => (
                    <span key={restriction} className="tag">{formatValue(restriction)}</span>
                  ))
                : <span className="tag">None</span>
              }
            </span>
          </div>
          <div className="profile-item list">
            <label>Medical Conditions</label>
            <span>
              {userProfile.health_factors.medical_conditions.length > 0
                ? userProfile.health_factors.medical_conditions.map(condition => (
                    <span key={condition} className="tag">{formatValue(condition)}</span>
                  ))
                : <span className="tag">None</span>
              }
            </span>
          </div>
          <div className="profile-item list">
            <label>Current Supplements</label>
            <span>
              {userProfile.health_factors.current_supplements.map(supplement => (
                <span key={supplement} className="tag">{formatValue(supplement)}</span>
              ))}
            </span>
          </div>
          <div className="profile-item">
            <label>Typical Bedtime</label>
            <span>{userProfile.health_factors.sleep_schedule.typical_bedtime}</span>
          </div>
          <div className="profile-item">
            <label>Typical Wake Time</label>
            <span>{userProfile.health_factors.sleep_schedule.typical_wake_time}</span>
          </div>
        </div>
      </div>

      <div className="profile-section">
        <h3>Lifestyle Factors</h3>
        <div className="profile-grid">
          <div className="profile-item">
            <label>Stress Level</label>
            <span>{formatValue(userProfile.lifestyle_factors.stress_level)}</span>
          </div>
          <div className="profile-item">
            <label>Occupation Type</label>
            <span>{formatValue(userProfile.lifestyle_factors.occupation_type)}</span>
          </div>
          <div className="profile-item">
            <label>Daily Active Hours</label>
            <span>{userProfile.lifestyle_factors.daily_active_hours} hours</span>
          </div>
          <div className="profile-item">
            <label>Nutrition Preparation</label>
            <span>{formatValue(userProfile.lifestyle_factors.nutrition_preparation)}</span>
          </div>
          <div className="profile-item">
            <label>Budget Level</label>
            <span>{formatValue(userProfile.lifestyle_factors.budget_level)}</span>
          </div>
          <div className="profile-item">
            <label>Time Constraints</label>
            <span>{formatValue(userProfile.lifestyle_factors.time_constraints)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile; 