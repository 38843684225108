import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatInterface from './components/ChatInterface';
import WhoopData from './components/WhoopData';
import Sidebar from './components/Sidebar';
import WhoopCredentials from './components/WhoopCredentials';
import WhoopCallback from './components/WhoopCallback';
import { sendMessage, fetchWhoopData } from './services/api';
import './styles/index.css';
import { auth } from './firebase/config';
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { saveChat, updateChat, loadUserChats, deleteChat } from './services/firestore';
import { db } from './firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import Profile from './components/Profile';
import ProfileForm from './components/ProfileForm';
import { saveUserProfile, getUserProfile } from './services/firestore';

function App() {
  const [messages, setMessages] = useState([]);
  const [whoopData, setWhoopData] = useState([]);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(Date.now());
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [currentWeekStart, setCurrentWeekStart] = useState(() => {
    const today = new Date();
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - 6);
    return sixDaysAgo.toISOString().split('T')[0];
  });
  const [user, setUser] = useState(null);
  const [hasWhoopCredentials, setHasWhoopCredentials] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const [showProfile, setShowProfile] = useState(false);

  // Initialize first chat
  useEffect(() => {
    if (user) {
      // Create a new chat instead of loading old ones
      const newChatId = Date.now();
      setCurrentChatId(newChatId);
      setChats([{
        id: newChatId,
        title: "New Chat",
        messages: []
      }]);
      setMessages([]);
      
      // Still fetch Whoop data
      fetchWhoopData(currentWeekStart, user.uid)
        .then(data => setWhoopData(data))
        .catch(error => console.error('Error fetching Whoop data:', error));
    } else {
      // Reset state when user logs out
      setChats([]);
      setMessages([]);
      setCurrentChatId(Date.now());
      setWhoopData([]);
    }
  }, [user, currentWeekStart]);

  const handleWeekChange = (newDate) => {
    setCurrentWeekStart(newDate);
  };

  const handleNewChat = () => {
    // Save current chat before creating new one
    if (user && messages.length > 0) {
      saveChat(user.uid, currentChatId, {
        title: messages[0]?.text?.slice(0, 30) + "..." || "New Chat",
        messages: messages
      });
    }

    const newChatId = Date.now();
    setChats(prev => [...prev, {
      id: newChatId,
      title: "New Chat",
      messages: []
    }]);
    setCurrentChatId(newChatId);
    setMessages([]);
  };

  const handleChatSelect = (chatId) => {
    // Save current chat before switching
    if (user && messages.length > 0) {
      updateChat(user.uid, currentChatId, {
        title: messages[0]?.text?.slice(0, 30) + "..." || "New Chat",
        messages: messages
      });
    }

    setCurrentChatId(chatId);
    const selectedChat = chats.find(chat => chat.id === chatId);
    setMessages(selectedChat?.messages || []);
  };

  const handleSendMessage = async (messageData) => {
    try {
      const userMessage = {
        type: 'user',
        text: messageData.query
      };
      
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);

      // If this is the first message in the chat, save it immediately
      if (messages.length === 0) {
        const chatTitle = messageData.query.slice(0, 30) + "...";
        setChats(prev => prev.map(chat => 
          chat.id === currentChatId 
            ? { ...chat, title: chatTitle, messages: updatedMessages }
            : chat
        ));
        
        // Save to Firestore immediately
        await saveChat(user.uid, currentChatId, {
          title: chatTitle,
          messages: updatedMessages
        });
      }

      const updatedHistory = [...conversationHistory, userMessage];
      setConversationHistory(updatedHistory);

      const response = await sendMessage(
        messageData.query, 
        messageData.whoopData, 
        updatedHistory,
        user.uid
      );
      
      const aiMessage = {
        type: 'ai',
        response: response.response
      };

      const finalMessages = [...updatedMessages, aiMessage];
      setMessages(finalMessages);
      
      // Update chat in Firestore with AI response
      if (user) {
        await updateChat(user.uid, currentChatId, {
          title: messages.length === 0 
            ? messageData.query.slice(0, 30) + "..." 
            : chats.find(chat => chat.id === currentChatId)?.title,
          messages: finalMessages
        });
      }

      setConversationHistory(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = {
        type: 'error',
        response: error.message || 'Sorry, there was an error processing your message.'
      };
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleDeleteChat = async (chatId) => {
    if (!user) return;

    try {
      await deleteChat(user.uid, chatId);
      
      // Remove chat from state
      setChats(prev => prev.filter(chat => chat.id !== chatId));
      
      // If the deleted chat was the current chat, switch to another chat
      if (chatId === currentChatId) {
        const remainingChats = chats.filter(chat => chat.id !== chatId);
        if (remainingChats.length > 0) {
          setCurrentChatId(remainingChats[0].id);
          setMessages(remainingChats[0].messages || []);
        } else {
          // If no chats remain, create a new empty chat
          handleNewChat();
        }
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  useEffect(() => {
    const checkWhoopCredentials = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists() && userDoc.data().whoopCredentials) {
          setHasWhoopCredentials(true);
        } else {
          setHasWhoopCredentials(false);
        }
      }
    };

    checkWhoopCredentials();
  }, [user]);

  const handleCredentialsSet = () => {
    setHasWhoopCredentials(true);
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      if (user) {
        try {
          const response = await checkAuthorization(user.uid);
          setIsAuthorized(response.authorized);
        } catch (error) {
          if (error.message === 'You are not authorized to use this service.') {
            setIsAuthorized(false);
          }
        }
      } else {
        setIsAuthorized(null);
      }
    };

    checkAuthorization();
  }, [user]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setLoginError('Invalid email or password');
      console.error('Login error:', error);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoginError('');
    
    if (password !== confirmPassword) {
      setLoginError('Passwords do not match');
      return;
    }
    
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          setLoginError('Email already in use');
          break;
        case 'auth/weak-password':
          setLoginError('Password should be at least 6 characters');
          break;
        default:
          setLoginError('Error creating account');
          console.error('Sign up error:', error);
      }
    }
  };

  // Add this effect to load chats when user logs in or page refreshes
  useEffect(() => {
    const loadChats = async () => {
      if (user) {
        try {
          const userChats = await loadUserChats(user.uid);
          if (userChats.length > 0) {
            setChats(userChats);
            // Find the most recent chat
            const mostRecentChat = userChats.reduce((latest, current) => {
              return !latest.updatedAt || current.updatedAt > latest.updatedAt ? current : latest;
            }, userChats[0]);
            setCurrentChatId(mostRecentChat.id);
            setMessages(mostRecentChat.messages || []);
          } else {
            // If no chats exist, create a new empty chat
            const newChatId = Date.now();
            setCurrentChatId(newChatId);
            setChats([{
              id: newChatId,
              title: "New Chat",
              messages: []
            }]);
            setMessages([]);
          }
        } catch (error) {
          console.error('Error loading chats:', error);
        }
      }
    };

    loadChats();
  }, [user]); // Only run when user changes

  useEffect(() => {
    const loadUserProfile = async () => {
      if (user) {
        try {
          const profile = await getUserProfile(user.uid);
          setUserProfile(profile);
        } catch (error) {
          console.error('Error loading user profile:', error);
        }
      }
    };

    loadUserProfile();
  }, [user]);

  const handleUpdateProfile = async (profileData) => {
    try {
      await saveUserProfile(user.uid, profileData);
      setUserProfile(profileData);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserProfile(null);
      setHasWhoopCredentials(false);
      setIsAuthorized(null);
      setMessages([]);
      setChats([]);
      setCurrentChatId(Date.now());
      setWhoopData([]);
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setLoginError('');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={
            !user ? (
              <div className="login-prompt">
                <h2>Welcome to RecoverAI-Coach</h2>
                <p>Please {isSignUp ? 'sign up' : 'sign in'} to access your personalized coaching experience.</p>
                {loginError && <div className="auth-error">{loginError}</div>}
                <form onSubmit={isSignUp ? handleSignUp : handleLogin} className="login-form">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {isSignUp && (
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  )}
                  <button type="submit" className="auth-submit">
                    {isSignUp ? 'Sign Up' : 'Sign In'}
                  </button>
                </form>
                <button 
                  className="auth-switch" 
                  onClick={() => {
                    setIsSignUp(!isSignUp);
                    setLoginError('');
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                  }}
                >
                  {isSignUp 
                    ? 'Already have an account? Sign In' 
                    : "Don't have an account? Sign Up"}
                </button>
              </div>
            ) : !userProfile ? (
              <div className="profile-setup-container">
                <ProfileForm onSubmit={handleUpdateProfile} />
              </div>
            ) : !hasWhoopCredentials ? (
              <div className="whoop-credentials-container">
                <WhoopCredentials 
                  user={user} 
                  onCredentialsSet={handleCredentialsSet} 
                />
              </div>
            ) : (
              <>
                <div className="sidebar-container">
                  <Sidebar
                    isOpen={isSidebarOpen}
                    onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
                    chats={chats}
                    currentChatId={currentChatId}
                    onNewChat={handleNewChat}
                    onChatSelect={handleChatSelect}
                    onDeleteChat={handleDeleteChat}
                    user={user}
                    onSignOut={handleSignOut}
                    showProfile={showProfile}
                    setShowProfile={setShowProfile}
                  />
                </div>
                <div className="main-content">
                  {showProfile ? (
                    <Profile userProfile={userProfile} onUpdateProfile={handleUpdateProfile} />
                  ) : (
                    <ChatInterface
                      messages={messages}
                      onSendMessage={handleSendMessage}
                      whoopData={whoopData}
                    />
                  )}
                </div>
                <div className="whoop-data">
                  <WhoopData 
                    data={whoopData} 
                    onWeekChange={handleWeekChange}
                    currentWeekStart={currentWeekStart}
                  />
                </div>
              </>
            )
          } />
          <Route path="/whoop/callback" element={<WhoopCallback />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;