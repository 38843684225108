import React, { useState, useEffect } from 'react';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

function WhoopCredentials({ user, onCredentialsSet }) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if user already has Whoop credentials
    const checkExistingCredentials = async () => {
      if (user) {
        try {
          // Get the current user's ID token
          const authInstance = getAuth();
          const idToken = await authInstance.currentUser.getIdToken();

          // Check authorization with backend
          const authResponse = await fetch('/api/auth/check', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idToken }),
          });

          if (!authResponse.ok) {
            throw new Error('Not authorized');
          }

          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists() && userDoc.data().whoopAuth) {
            onCredentialsSet(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error checking credentials:', error);
          setError('You are not authorized to use this service.');
          setIsLoading(false);
        }
      }
    };

    checkExistingCredentials();
  }, [user, onCredentialsSet]);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.origin !== window.location.origin) return;

      if (event.data.type === 'WHOOP_OAUTH_CALLBACK') {
        const { code, state } = event.data;

        try {
          const authInstance = getAuth();
          const idToken = await authInstance.currentUser.getIdToken();

          // Exchange code for token
          const tokenResponse = await fetch('/api/auth/whoop/callback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, state, idToken }),
          });

          if (!tokenResponse.ok) {
            const errorData = await tokenResponse.json();
            throw new Error(errorData.error || 'Failed to exchange code for token');
          }

          const data = await tokenResponse.json();
          if (data.success) {
            onCredentialsSet(true);
          } else {
            throw new Error(data.error || 'Authentication failed');
          }
        } catch (err) {
          console.error('Error during OAuth callback:', err);
          setError(err.message || 'Authentication failed');
        }
      } else if (event.data.type === 'WHOOP_OAUTH_ERROR') {
        setError(event.data.error_description || 'OAuth error occurred');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onCredentialsSet]);

  const handleConnectWhoop = async () => {
    try {
      setError('');
      const authInstance = getAuth();
      const idToken = await authInstance.currentUser.getIdToken();

      // Get OAuth URL from backend
      const response = await fetch('/api/auth/whoop/oauth-url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to get OAuth URL');
      }

      const { url } = await response.json();
      
      // Open OAuth window
      const width = 600;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      
      window.open(
        url,
        'Whoop Authorization',
        `width=${width},height=${height},left=${left},top=${top}`
      );
    } catch (error) {
      console.error('Error initiating OAuth:', error);
      setError(error.message);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="whoop-credentials">
      <h2>Connect Your Whoop Account</h2>
      {error && <div className="error">{error}</div>}
      <button onClick={handleConnectWhoop} className="connect-whoop-btn">
        Connect to Whoop
      </button>
    </div>
  );
}

export default WhoopCredentials;