import React, { useState } from 'react';
import { FaPlus, FaComment, FaSignInAlt, FaTimes } from 'react-icons/fa';
import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';

function Sidebar({ chats, currentChatId, onChatSelect, onNewChat, isOpen, user, onDeleteChat, onProfileClick }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleAuth = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      setEmail('');
      setPassword('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className={`sidebar ${!isOpen ? 'closed' : ''}`}>
      {user ? (
        <>
          <div className="user-profile">
            <span className="user-name">{user.email}</span>
          </div>
          <button className="new-chat-button" onClick={onNewChat}>
            <FaPlus /> New chat
          </button>
          <div className="chat-history">
            {chats.map(chat => (
              <div
                key={chat.id}
                className={`chat-history-item ${chat.id === currentChatId ? 'active' : ''}`}
              >
                <button
                  className="chat-button"
                  onClick={() => onChatSelect(chat.id)}
                >
                  <FaComment className="chat-icon" />
                  <span className="chat-title">{chat.title || 'New Chat'}</span>
                </button>
                <button 
                  className="delete-chat-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteChat(chat.id);
                  }}
                  aria-label="Delete chat"
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
          <div className="sidebar-footer">
            <button className="profile-button" onClick={onProfileClick}>
              Profile
            </button>
            <button className="sign-out-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        </>
      ) : (
        <div className="auth-form">
          <h2>{isSignUp ? 'Create Account' : 'Sign In'}</h2>
          {error && <div className="auth-error">{error}</div>}
          <form onSubmit={handleAuth}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="auth-submit">
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
          </form>
          <button 
            className="auth-switch" 
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
          </button>
        </div>
      )}
    </div>
  );
}

export default Sidebar; 