import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function WhoopCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get code and state from URL parameters
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');

    if (code && state) {
      // Send message to parent window with OAuth data
      window.opener.postMessage({
        type: 'WHOOP_OAUTH_CALLBACK',
        code,
        state
      }, window.location.origin);
      
      // Close this window
      window.close();
    } else {
      // Handle error case
      const error = params.get('error');
      const error_description = params.get('error_description');
      
      if (error) {
        window.opener.postMessage({
          type: 'WHOOP_OAUTH_ERROR',
          error,
          error_description
        }, window.location.origin);
        window.close();
      } else {
        // If no code/state and no error, redirect to home
        navigate('/');
      }
    }
  }, [navigate, location]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-4">Processing Whoop Authorization</h2>
        <p>Please wait while we complete the authorization process...</p>
      </div>
    </div>
  );
}

export default WhoopCallback;
